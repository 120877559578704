import * as React from 'react';

export const ErrorSvg = (props: any) => {
	return (
		<svg viewBox='0 0 512 512' {...props}>
			<polygon
				points='478.2 489.5 512 489.5 512 90 455.7 56.3 '
				fill='#B9B9B9'
			/>
			<polygon
				points='0 90 0 489.5 478.2 489.5 478.2 56.3 '
				fill='#DCDCDC'
			/>
			<polygon
				points='478.2 22.5 455.7 56.3 478.2 90 512 90 512 22.5 '
				fill='#FE5F1A'
			/>
			<polygon
				points='427.6 22.5 393.8 56.3 427.6 90 478.2 90 478.2 22.5 '
				fill='#FE834D'
			/>
			<polygon
				points='393.8 22.5 360.1 56.3 393.8 90 427.6 90 427.6 22.5 '
				fill='#969696'
			/>
			<rect y={22.5} width={393.8} height={67.5} fill='#B9B9B9' />
			<polygon
				points='444.5 123.8 422 346.7 444.5 455.7 478.2 455.7 478.2 123.8 '
				fill='#D5F6F5'
			/>
			<rect x={33.8} y={123.8} width={410.7} height={332} fill='#FFF' />
			<circle cx={469.8} cy={56.3} r={8.4} fill='#272727' />
			<path
				d='M256 178.9v221.7c61.2 0 110.8-49.6 110.8-110.8S317.2 178.9 256 178.9z'
				fill='#FE5F1A'
			/>
			<path
				d='M333.1 289.8c0-61.2-34.5-110.8-77.1-110.8 -61.2 0-110.8 49.6-110.8 110.8s49.6 110.8 110.8 110.8C298.6 400.6 333.1 351 333.1 289.8z'
				fill='#FE834D'
			/>
			<path
				d='M267.9 289.8l40.6-40.6c3.3-3.3 3.3-8.6 0-11.9 -3.3-3.3-8.6-3.3-11.9 0L256 277.8l-40.6-40.6c-3.3-3.3-8.6-3.3-11.9 0 -3.3 3.3-3.3 8.6 0 11.9l40.6 40.6 -40.6 40.6c-3.3 3.3-3.3 8.6 0 11.9 3.3 3.3 8.6 3.3 11.9 0L256 301.7l40.6 40.6c3.3 3.3 8.6 3.3 11.9 0 3.3-3.3 3.3-8.6 0-11.9L267.9 289.8z'
				fill='#FFF'
			/>
		</svg>
	);
};
