import * as React from 'react';

export const NotfoundSvg = (props: any) => {
	return (
		<svg viewBox='0 0 287 253' {...props}>
			<g
				id='Default'
				stroke='none'
				strokeWidth={1}
				fill='none'
				fillRule='evenodd'
			>
				<g
					id='Search-No-Results'
					transform='translate(-1467.000000, -753.000000)'
				>
					<g
						id='Illustration'
						transform='translate(1468.000000, 753.000000)'
					>
						<path
							d='M247.419355,107.196429 C247.419355,166.401482 199.422048,214.392857 140.209677,214.392857 C80.9973065,214.392857 33,166.401482 33,107.196429 C33,47.991375 80.9973065,0 140.209677,0 C199.422048,0 247.419355,47.991375 247.419355,107.196429'
							id='Fill-1'
							fill='#E9EBEC'
						/>
						<polygon
							id='Fill-3'
							fill='#FFFFFF'
							points='28.2859548 51.2678571 0.318212903 79.2321429 28.2859548 107.196429 126.173052 107.196429 126.173052 51.2678571'
						/>
						<polygon
							id='Stroke-5'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinejoin='round'
							points='28.2859548 51.2678571 0.318212903 79.2321429 28.2859548 107.196429 126.173052 107.196429 126.173052 51.2678571'
						/>
						<polygon
							id='Fill-7'
							fill='#FDD692'
							points='256.689647 79.2321429 149.479969 79.2321429 149.479969 135.160714 256.689647 135.160714 284.657389 107.196429'
						/>
						<polygon
							id='Stroke-9'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinejoin='round'
							points='256.689647 79.2321429 149.479969 79.2321429 149.479969 135.160714 256.689647 135.160714 284.657389 107.196429'
						/>
						<polyline
							id='Stroke-11'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
							points='224.060615 79.2321429 256.689647 79.2321429 284.657389 107.196429 256.689647 135.160714 149.479969 135.160714 149.479969 79.2321429 172.786421 79.2321429'
						/>
						<path
							d='M147.148858,18.6428571 C135.565552,18.6428571 126.173052,28.0341964 126.173052,39.6160714 L126.173052,251.678571 L168.124665,251.678571 L168.124665,39.6160714 C168.124665,28.0341964 158.732165,18.6428571 147.148858,18.6428571'
							id='Fill-17'
							fill='#FFFFFF'
						/>
						<path
							d='M147.148858,18.6428571 C135.565552,18.6428571 126.173052,28.0341964 126.173052,39.6160714 L126.173052,251.678571 L168.124665,251.678571 L168.124665,39.6160714 C168.124665,28.0341964 158.732165,18.6428571 147.148858,18.6428571 Z'
							id='Stroke-19'
							stroke='#676767'
							strokeWidth={1.2}
						/>
						<path
							d='M126.173052,93.2142857 L126.173052,39.6160714 C126.173052,28.0341964 135.565552,18.6428571 147.148858,18.6428571 C158.732165,18.6428571 168.124665,28.0341964 168.124665,39.6160714 L168.124665,251.678571 L126.173052,251.678571 L126.173052,107.196429'
							id='Stroke-21'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M175,251 L184.322581,251'
							id='Path'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M195,251 L204.322581,251'
							id='Path'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M215,251 L224.322581,251'
							id='Path'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M68,251 L77.3225806,251'
							id='Path'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M88,251 L97.3225806,251'
							id='Path'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M108,251 L117.322581,251'
							id='Path'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M168.125131,107 L200.754163,107'
							id='Stroke-28'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<polyline
							id='Stroke-30'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
							points='65.5762774 51.2678571 28.2859548 51.2678571 0.318212903 79.2321429 28.2859548 107.196429 154.140794 107.196429'
						/>
						<polygon
							id='Fill-32'
							fill='#EC7257'
							points='60.9149871 135.160714 32.9472452 163.125 60.9149871 191.089286 144.818213 191.089286 144.818213 135.160714'
						/>
						<polygon
							id='Stroke-34'
							stroke='#676767'
							strokeWidth={1.2}
							points='60.9149871 135.160714 32.9472452 163.125 60.9149871 191.089286 144.818213 191.089286 144.818213 135.160714'
						/>
						<polyline
							id='Stroke-36'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
							points='107.52789 191.089286 144.818213 191.089286 144.818213 135.160714 60.9149871 135.160714 32.9472452 163.125 60.9149871 191.089286 79.5601484 191.089286'
						/>
						<path
							d='M210.076744,107 L219.399324,107'
							id='Stroke-42'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M228.721905,107 L238.044485,107'
							id='Stroke-44'
							stroke='#676767'
							strokeWidth={1.2}
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M69.2316613,90.7678768 C69.2316613,89.9103054 69.9122097,89.2251804 70.7745484,89.2251804 C71.5949355,89.2251804 72.2754839,89.9103054 72.2754839,90.7678768 C72.2754839,91.5928232 71.5949355,92.2732875 70.7745484,92.2732875 C69.9122097,92.2732875 69.2316613,91.5928232 69.2316613,90.7678768 L69.2316613,90.7678768 Z M69.9495,83.6369839 C69.9495,80.7706446 71.5623065,79.1999839 73.529371,77.2285018 C75.1794677,75.5786089 76.5778548,74.2549661 76.5778548,71.9945196 C76.5778548,69.5569661 75.1095484,67.8045375 71.9212258,67.8045375 C68.8028226,67.8045375 66.6493065,69.8459304 66.3649677,73.1783411 L64.5377419,73.1783411 C64.7894516,68.9836982 67.7633548,66.1919304 71.9538548,66.1919304 C76.4333548,66.1919304 78.5495806,68.8392161 78.5495806,71.9246089 C78.5495806,74.8282339 76.7922742,76.2963589 74.8578387,78.2305554 C73.0306129,80.0202696 71.6695161,81.3811982 71.6695161,83.7814661 L71.6695161,85.6830375 L69.9495,85.6830375 L69.9495,83.6369839 Z'
							id='Fill-46'
							fill='#545454'
						/>
						<path
							d='M87.8768226,174.660734 C87.8768226,173.803162 88.557371,173.118037 89.4197097,173.118037 C90.2400968,173.118037 90.9206452,173.803162 90.9206452,174.660734 C90.9206452,175.48568 90.2400968,176.166145 89.4197097,176.166145 C88.557371,176.166145 87.8768226,175.48568 87.8768226,174.660734 L87.8768226,174.660734 Z M88.5946613,167.529841 C88.5946613,164.663502 90.2074677,163.092841 92.1745323,161.121359 C93.824629,159.471466 95.2230161,158.147823 95.2230161,155.887377 C95.2230161,153.449823 93.7547097,151.697395 90.5663871,151.697395 C87.4479839,151.697395 85.2944677,153.738787 85.010129,157.071198 L83.1829032,157.071198 C83.4346129,152.876555 86.4085161,150.084787 90.5990161,150.084787 C95.0785161,150.084787 97.1947419,152.732073 97.1947419,155.817466 C97.1947419,158.721091 95.4374355,160.189216 93.503,162.123412 C91.6757742,163.913127 90.3146774,165.274055 90.3146774,167.674323 L90.3146774,169.575895 L88.5946613,169.575895 L88.5946613,167.529841 Z'
							id='Fill-48'
							fill='#FFFFFF'
						/>
						<path
							d='M111.183274,174.660734 C111.183274,173.803162 111.863823,173.118037 112.726161,173.118037 C113.546548,173.118037 114.227097,173.803162 114.227097,174.660734 C114.227097,175.48568 113.546548,176.166145 112.726161,176.166145 C111.863823,176.166145 111.183274,175.48568 111.183274,174.660734 L111.183274,174.660734 Z M111.901113,167.529841 C111.901113,164.663502 113.513919,163.092841 115.480984,161.121359 C117.131081,159.471466 118.529468,158.147823 118.529468,155.887377 C118.529468,153.449823 117.061161,151.697395 113.872839,151.697395 C110.754435,151.697395 108.600919,153.738787 108.316581,157.071198 L106.489355,157.071198 C106.741065,152.876555 109.714968,150.084787 113.905468,150.084787 C118.384968,150.084787 120.501194,152.732073 120.501194,155.817466 C120.501194,158.721091 118.743887,160.189216 116.809452,162.123412 C114.982226,163.913127 113.621129,165.274055 113.621129,167.674323 L113.621129,169.575895 L111.901113,169.575895 L111.901113,167.529841 Z'
							id='Fill-50'
							fill='#FFFFFF'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
