import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import CityCardList from 'Components/common/cityCardListV2';
import FeedSectionHeader from 'Components/common/feedSectionHeader';

import { NotfoundSvg } from 'Assets/svg/alert/NotfoundSvg';

import { strings } from 'Constants/strings';

export const PageWrapper = styled.div`
	margin-top: 6rem;
	padding-bottom: 3.125rem;

	#main-fold-wrapper {
		align-items: center;
		display: flex;
		height: 25rem;
		justify-content: center;
	}

	#page-header {
		align-items: center;
		display: flex;
		justify-content: center;
		margin: 0 auto;
		padding: 3.125rem;
	}

	svg {
		height: 11rem;
		margin-top: 1.625rem;
		width: 18.75rem;
	}

	#section-error-code {
		padding-right: 6.25rem;
	}

	h1 {
		color: #cbcbcb;
		font-size: 11.5rem;
		font-weight: 900;
		line-height: 1;
	}

	h2 {
		color: #9a9a9a;
		margin-left: 0.125rem;
	}
`;

const NotFoundElements = () => {
	return (
		<PageWrapper id='not-found-page'>
			<div id='main-fold-wrapper'>
				<div id='main-fold-content'>
					<div id='page-header'>
						<NotfoundSvg />
						<div id='section-error-code'>
							<h1>404</h1>
							<h2>
								The page you are looking for cannot be found
							</h2>
						</div>
					</div>
				</div>
			</div>
			<div className='core-column-container'>
				<FeedSectionHeader title={strings.CT_HEADER} />
				<CityCardList />
			</div>
		</PageWrapper>
	);
};

export default NotFoundElements;
