import React from 'react';
import { connect } from 'react-redux';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import Conditional from 'Components/common/conditional';

import { ErrorSvg } from 'Assets/svg/alert/ErrorSvg';

import { isProductionEnvironment } from 'Utils/envUtils';
import { getErrorInfo } from 'Utils/stateUtils';

type ErrorElementsProps = {
	errorInfo?: string;
};

const ErrorPageWrapper = styled.div`
	#main-fold-wrapper {
		align-items: center;
		display: flex;
		height: 70vh;
		justify-content: center;
	}

	#main-fold-content {
		padding: 5vh 10vw;
		text-align: center;
	}

	h1 {
		color: #cbcbcb;
		font-family: halyard-display, -apple-system, BlinkMacSystemFont,
			Segoe UI, Roboto, Helvetica, Arial, sans-serif;
		font-size: 2.875rem !important;
		font-weight: 900;
		line-height: 1;
	}

	h2 {
		color: #9a9a9a;
		margin-top: 5vh;
	}
`;

const ErrorElements = ({ errorInfo }: ErrorElementsProps) => (
	<ErrorPageWrapper id='error-page'>
		<div id='main-fold-wrapper'>
			<div id='main-fold-content'>
				<div id='page-header'>
					<ErrorSvg />
					<h1>500</h1>
					<h2>Please check back in a minute, we are working on it</h2>
				</div>
			</div>
		</div>

		<Conditional if={!isProductionEnvironment()}>
			<div className='core-column-container'>
				<h2>Error Info</h2>
				<p>{errorInfo}</p>
			</div>
		</Conditional>
	</ErrorPageWrapper>
);

const mapStateToProps = (state: any) => ({
	errorInfo: getErrorInfo(state),
});

const Error = connect(mapStateToProps)(ErrorElements);

export default Error;
