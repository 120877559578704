import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import { NotfoundSvg } from 'Assets/svg/alert/NotfoundSvg';

const PageWrapper = styled.div`
	#main-fold-wrapper {
		align-items: center;
		display: flex;
		height: 70vh;
		justify-content: center;
	}

	#main-fold-content {
		padding: 5vh 10vw;
		text-align: center;
	}

	h1 {
		color: #cbcbcb;
		font-size: 2.875rem;
		font-weight: 900;
		line-height: 1;
		font-family: Halyard-display;
	}

	h2 {
		color: #9a9a9a;
		margin-top: 5vh;
	}
`;

const NotFound = () => (
	<PageWrapper id='not-found-page'>
		<div id='main-fold-wrapper'>
			<div id='main-fold-content'>
				<div id='page-header'>
					<NotfoundSvg />
					<h1>404</h1>
					<h2>The page you are looking for cannot be found</h2>
				</div>
			</div>
		</div>
	</PageWrapper>
);

export default NotFound;
