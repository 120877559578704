import React from 'react';
import { connect } from 'react-redux';

import Conditional from 'Components/common/conditional';

import { ErrorSvg } from 'Assets/svg/alert/ErrorSvg';

import { isProductionEnvironment } from 'Utils/envUtils';
import { getErrorInfo } from 'Utils/stateUtils';

import { PageWrapper } from './notFound';

type ErrorElementsProps = {
	errorInfo?: string;
};

const ErrorElements = ({ errorInfo }: ErrorElementsProps) => (
	<PageWrapper id='error-page'>
		<div id='main-fold-wrapper'>
			<div id='main-fold-content'>
				<div id='page-header'>
					<ErrorSvg />
					<div id='section-error-code'>
						<h1>500</h1>
						<h2>
							Please check back in a minute, we are working on it
						</h2>
					</div>
				</div>
			</div>
		</div>

		<Conditional if={!isProductionEnvironment()}>
			<div className='core-column-container'>
				<h2>Error Info</h2>
				<p>{errorInfo}</p>
			</div>
		</Conditional>
	</PageWrapper>
);

const mapStateToProps = (state: any) => ({
	errorInfo: getErrorInfo(state),
});

const Error = connect(mapStateToProps)(ErrorElements);

export default Error;
